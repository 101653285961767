import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useParams, Link, useNavigate, useRouter, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Button from '../../UI/Buttons/Button';
import '../../../i18n';
import './LoginAuth.css'
import { format } from 'react-string-format';
import ErrorBoxCS from '../../UI/ErrorBox/ErrorBox';
import { SocialConnections } from '../Auth0/SocialConnections/SocialConnections'
import { APIRedirect } from '../../API/APIRequest'
import { ErrorBox, ProcessErrors } from '../../Utilities/ErrorBox'
import { IsSafeUrl, RedirectWarning } from '../../Utilities/SafeUrl'
import { useAuth } from '../AuthenticationProvider'
import useBodyClass from '../../../Hooks/useBodyClass';
import usePageTitle from '../../../Hooks/usePageTitle';
import ReactHtmlParser from 'html-react-parser';
import InputWrapperRefacture from '../../UI/Inputs/InputWrapperRefacture';
import useCreateUrl from '../../../Hooks/useCreateUrl';
import { isNewExpression } from 'typescript';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';
import useBrandingData from '../../../Hooks/useBrandingData';
import { emailRegexUtils } from '../../Utilities/EmailRegex';
import MessageBox from '../../UI/MessageBox/MessageBox';
import Transition from '../../UI/Transition/Transition';





export function LoginAuth(props) {
    // usePageTitle(`${!passwordRow ? t('register.title') : t('login.title')} - ${props.brandingLogin?.id}`);  

    const { t, i18n } = useTranslation();
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotTrusted, setIsNotTrusted] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isInactive, setIsInactive] = useState(false);
    const [enteredEmailTouched, setEnteredEmailTouched] = useState(false);
    const [IsEmailInvalid, setIsEmailInvalid] = useState(false);
    const [IsEmailBlank, setIsEmailBlank] = useState(false);
    const [buttonEvent, setButtonEvent] = useState(false);
    const [IsBlank, setIsBlank] = useState({ email: false, password: false });
    const [socialConnections, setSocialConnections] = useState([]);
    const [nextAction, setNextAction] = useState(null);
    const [buttonState, setButtonState] = useState("initial"); //the normal state of a button is initial, the other is loading state
    const [userPassword, setUserPassword] = useState("")
    const [emailValid, setEmailValid] = useState("");
    const inputRef = useRef(null);
    const headerRef = useRef(null);
    const formRef = useRef(null);
    const [combNotMatches, setCombNotMatches] = useState(false)
    const createUrl = useCreateUrl()
    const auth = useAuth();
    const navigate = useNavigate();
    const { environment } = useParams();
    const search = useLocation().search;
    const step = new URLSearchParams(search).get('step');
    const next = new URLSearchParams(search).get('next');
    const [userEmail, setUserEmail] = useState((step && localStorage.getItem('email')) ? (localStorage.getItem('email')) : "");
    const resetLinkEmail = new URLSearchParams(search).get('email') //query params
    const [mountState, setMountState]= useState("mount-start")
    const [isEditTrue, setIsEditTrue]  = useState(false);
    const { getBrandedHtml } = useBrandingData(props.brandingElement ? props.brandingElement : "");

    const emailRegex = emailRegexUtils; 

    const CheckEmail = (event) => {

        event.preventDefault();

        setErrors(null);
        let email = userEmail.toLowerCase();   //as it was giving an error of case sensitivity in the API
        
        //after assessing the validity of the email address it'll go further
        if (emailInputIsValid()) {
            setButtonState("loading")
            localStorage.setItem("email", email)
            auth.CheckRegisteredAccount(email).then((result) => {

                if (result.status === "email_found") {
                    setNextAction("password_required");
                    navigate(createUrl("login") + "&step=password");
                    setButtonState("initial")
                }

                else {
                    setNextAction("registration_required");
                }
                // console.log(nextAction);
            }).catch((errors) => {

                setErrors(ProcessErrors(errors));
                setButtonState("initial")
            });
        }
    }

    //componentDidMount
    useEffect(() => {
        sessionStorage.setItem("ad_ref_token", null);
        headerRef.current?.focus();
        setErrors(null);
       
    }, [])

 
    //for login-screen 1st(email-required) and 2nd(password-required)
    useEffect(() => {
        if(step) {
            setNextAction("password_required")
            setErrors(null);
            //if we find the query param email in the redirection link
            if (resetLinkEmail) {
                setUserEmail(resetLinkEmail)
            }
        }
        else {
            //when we land on the login-screen 1
            localStorage.clear();
            setErrors(null);
            setNextAction(null)
        }
    }, [step])

    //email address validation

    const Validated = () => {

        let validated = true;
        let passwordValue = userPassword
        let emailValue = userEmail || "";
        let temp = emailValue?.split("@");  //for extracting the local part
        let localPart = temp ? temp[0] : "";
        setIsBlank(IsBlank => ({ ...IsBlank, password: false }))
        if ((emailValue?.match(emailRegex) || []).length <= 0 || localPart.length > 64) {
            validated = false;
        }

        //add email blank condtion as well as it is in isEmailInvalid() todo

        else if (nextAction === "password_required") {
            if (passwordValue < 1) {
                setIsBlank(IsBlank => ({ ...IsBlank, password: true }))
                validated &= false;

            }
            else { validated &= true }
        }

        if (!validated) {
            focusOnFirstError();
        }

        return validated;

    }

    //onSubmit focusing on first error causing input
    const focusOnFirstError = () => {
        setTimeout(() => {
            let firstElement = formRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
        }, 200)

    }

    //email input validation - not blank and valid email address

    const emailInputIsValid = () => {
        setIsEmailBlank(false);
        setIsEmailInvalid(false);
        let emailValue = userEmail;
        let isValid = Validated();
        if (emailValue == "") {
            setIsEmailBlank(true)
            return false;
        }
        else if (!isValid) {
            setIsEmailInvalid(true);
            return false;
        }
        else {
            return true;
        }

    }

    const PassThru = (event) => {
        event.preventDefault();
        let passThruUrl = auth.PassThruUrl();
        IsSafeUrl(passThruUrl, environment).then((result) => {
            if (result) {

                window.location.replace(passThruUrl);
            }
            else {
                if (!isNotTrusted) {
                    setIsNotTrusted(passThruUrl);
                }


            }
        }).catch((errors) => {
            setErrors(ProcessErrors(errors));

            setButtonState("initial")
        });
    }
  //remove &params from url -VA
    function onCleanUrl(url) {
        const removeSymbol = url.indexOf('&');
        return removeSymbol !== -1 ? url.substring(0, removeSymbol) : url;
    }

    const Login = (event) => {

        event.preventDefault();
        setErrors(null)
        let cleanurl;
 
        if (Validated()) {
            setButtonState("loading")
            auth.Login(userEmail, userPassword).then((response) => {
                let loginResult = response;
                let returnUri = new URL(returnUrl);
                if (returnUri.hostname != window.location.hostname) {
                    let ad_ref_token = loginResult.ad_ref_token;
                    if (!next) {
                        if (returnUrl.indexOf('?') >= 0) {
                           
                            returnUrl += "&";
                        }
                        else {
                            returnUrl += "?";
                        }
                    } else {
                        returnUrl += "?";
                    }
                   /* if reset password send url ref + next - VA */
                    if (next) {
                        cleanurl = onCleanUrl(returnUrl) 
                        cleanurl += "?ref=" + ad_ref_token;
                        console.log(cleanurl)
                     
                    } else {
                        returnUrl += "ref=" + ad_ref_token;
                    }
                }
               
                IsSafeUrl(returnUrl, environment).then((result) => {
                    if (result) {

                        window.location.replace(returnUrl);
                    }
                    else {
                        if (!isNotTrusted) {
                            setIsNotTrusted(returnUrl);
                        }


                    }
                }).catch((errors) => {
                    setErrors(ProcessErrors(errors));
                   
                    setButtonState("initial")
                });
            }).catch((errors) => {
                setErrors(ProcessErrors(errors));
                setButtonState("initial")
                // added the checnk for concurrency error, which is not coming from the controller but pushing to the error list to get it from the languages.
                // if we add the error to the controller and the value is not error.concurrentusers the error won't appear
                if (errors[0] === "Maximum number of cookies exceeded") {
                    setErrors(ProcessErrors('error.concurrentusers'))
                    }  
                if (errors[0] === "error.deceased") {
                    setIsDisabled(true);
                    navigate(createUrl("warning"));
                }
                if (errors[0] === "error.inactive") {
                    setIsInactive(true)
                    navigate(createUrl("warning"));
                }
                if (errors[0] === "error.accountlocked") {
                    setIsInactive(true)
                    navigate(createUrl("warning"));
                }

            });
        }
    }

    const SocialSignIn = (event) => {
        event.preventDefault();

        let connection = event.target.dataset.connection;

        let returnUrl = auth.ReturnUrl();
        var data = {
            "ReturnUrl": returnUrl,
            "connection": connection
        };
        
        APIRedirect({
            "controller": "auth0",
            "action": "socialsignin",
            "environment": environment,
            data : data
        });
    }

    let passwordRow = '';

    useBodyClass(['login', 'anotherlogin', props.brandingElement?.bodyClass]);
    const pageTitle = !passwordRow ? `${t('register.title')} - ${props.brandingElement?.id}` : `${t('login.title')}  - ${props.brandingElement?.id}`;


    usePageTitle(pageTitle)

    useEffect(() => {

        // console.log(nextAction);
        if (isLoading) {
            auth.GetSocialConnections().then((connections) => {
                setSocialConnections(connections);
                setIsLoading(false);
            }).catch((errors) => {
                setErrors(ProcessErrors(errors));
                setIsLoading(false);
            })
        }
    }, [isLoading]);

    //componentWillUnmount
    useEffect(() => {
        setButtonState("initial")
        return () => {
        setButtonState("initial") //when we leave the page it sets button state to initial on change of url
        };
    }, [window.location.href])


    const handleOnBlur = (e) => {
        if (buttonEvent) {
            document.getElementById("password").focus();
            setButtonEvent(false)
            e.preventDefault();
        }
    }

    const onTextFieldKeyed = (event) => {
        if ((event.key.match(emailRegex) || []).length > 0) {
            event.preventDefault();
        }
    }

    let returnUrl = auth.ReturnUrl();
    if (nextAction==="password_required"){
        passwordRow = <>
            <div className="item-container">
                <div className='item-wrapper'>
                    <InputWrapperRefacture label={t('login.passwordlabel')}
                        inputSize="full-width"
                        inputFieldType="password"
                        showBtnText={t('input.showpasswordlabel')}
                        hideBtnText={t('input.hidepasswordlabel')}
                        value={userPassword}
                        id="password"
                        name="password"
                        ref={inputRef}
                        onBlur={(e) => { handleOnBlur(e) }}
                        setButtonEvent={setButtonEvent}
                        aria-invalid={IsBlank.password}
                        aria-describedby="password-blank-error"
                        invalidClass={(IsBlank.password) ? "Invalid-input" : ""}
                        onChange={(e) => setUserPassword(e.target.value)} />

                    <span role="alert" id="password-blank-error"> {IsBlank.password && <ErrorBoxCS>{t('login.blankpasswordinput')}</ErrorBoxCS>}</span>

                    <a href="JavaScript:void(0)" className="link" onClick={() => { navigate(createUrl("forgottenpassword"), { replace: true }) }}><p>{t('forgottenpassword.linktext')}</p></a>
                </div>
            </div>
        </>}
    

    useEffect(() => {
        if (nextAction == "registration_required") {

            navigate(createUrl("register"));
        }
        if (nextAction == "forgot_password") {

            navigate(createUrl("forgottenpassword"));
        }
        if (nextAction === "password_required") {

            setTimeout(() => { inputRef.current?.focus() }, 100);


        }

        // console.log(nextAction, "inside-useEffect")

    }, [nextAction])

    const termsText = props.brandingElement?.termsText || '';

    useBodyClass(['login', 'anotherlogin', props.brandingElement?.bodyClass]);
    const title = !passwordRow ? `${t('register.title')} - ${props.brandingElement?.id}` : `${t('login.title')} - ${ props.brandingElement?.id }`;
    const titleEdit = !passwordRow ? `${t('register.titleEdit')} - ${props.brandingElement?.id}` : `${t('login.title')} - ${ props.brandingElement?.id }`;

    //usePageTitle(title + props.brandingElement.id);  
    usePageTitle(!isEditTrue ? title : titleEdit);
  

    //edit button on email field
    const handleOnEdit = (val) => {
        setNextAction(val);
        setErrors(null);
        navigate(createUrl("login"));
        setIsEditTrue(true)
        
    }

    let chooseError = <GenericErrorBox errors={errors} />

    if (isDisabled) {
        chooseError = '';
    } else {
        if (isInactive) {
            chooseError = '';

        }
    }

    console.log(auth);

    return (
     
        <Fragment>
              

            <RedirectWarning isNotTrusted={isNotTrusted} returnUrl={isNotTrusted} />
           
           <div className="container">
        
               
                <div className="item-container" aria-live="polite">
                    {/*to make the screen reader read out the module heading we explicitly make it focus on it as the mounting happens*/}
                    <h1 className="module-heading focusableHeader" tabIndex="-1" ref={headerRef}> {!passwordRow ? t('login.createorlogin') : t('login.title') } </h1>
                </div>
            
                <span aria-live="polite"> {chooseError} </span>
               
                <form ref={formRef} onSubmit={nextAction === "password_required" ? Login : CheckEmail} className={isNotTrusted ? "noshow" : ""}>
             
                    <div className="auth-row">
                 
                        <div className="item-container">
                            <div className="item-wrapper">

                                <InputWrapperRefacture label={t('register.emaillabel')}
                                    inputSize="full-width"
                                    inputFieldType="text"
                                    id="email"
                                    name="email"
                                 type="email"
                                    placeholder={t('placeholder.email')}
                                    onClickFunction={nextAction == "password_required" && handleOnEdit}  //the edit button should only show when psw-requires is on
                                    btnText={t('input.edittextlabel')}
                                    value={userEmail}
                                    onKeyPress={onTextFieldKeyed}
                                    invalidClass={(IsEmailBlank || IsEmailInvalid) ? "Invalid-input" : ""}
                                    aria-invalid={(IsEmailBlank || IsEmailInvalid)}
                                    aria-describedby="email-errors"
                                    onChange={(e) => { setUserEmail(e.target.value) }}
                                    readOnly={nextAction == "password_required" ? "readOnly" : ""} />

                                <span role="alert" id="email-errors" >
                                    {IsEmailBlank && <ErrorBoxCS>{t('login.blankemailinput')}</ErrorBoxCS>}
                                    {IsEmailInvalid ? props.brandingElement?.mailValidError ? <ErrorBoxCS> {getBrandedHtml('mailValidError')} </ErrorBoxCS> : <ErrorBoxCS>{t('login.incorrectemailinput')}</ErrorBoxCS> : ""}
                                </span>
                            </div>
                        </div>
                       
                    </div>
                   
                    
                     <div aria-live="polite" className= {`${nextAction==="password_required" ? "password-block-after" :  "password-block-before" }`}>
                           {passwordRow}
                        </div>

                    
               
                    <div className="item-container login-transition">
                        {buttonState === "initial" ?
                            <Button type="button" buttonSize="full-width">{t('login.continuebutton')}</Button>
                            : buttonState === "loading" ?
                                <Button type="button" hasIcon="updating" isDisabled="true" buttonSize="full-width">{t('login.continuebutton')}</Button>
                                : ""}
                    </div>
                    {auth.PassThruUrl() ?
                        <div className="item-container login-transition">
                            <div className="text-between-buttons">{t('login.guestcontinuetext')}</div>
                            <a class="ghost-button full-width, text-between-buttons" buttonSize="full-width"  onClick={PassThru}>{t('login.guestcontinuebutton')}</a>
                        </div>
                        : ""
                    }
                    
                </form>
                {auth.authProviderType === "auth0" ?
                    <>
                        <Transition timer={100} mountAnimation="mount-heightAnimation">
                            {!passwordRow ? <div className="item-container">
                                <div className="text-between-border">{t('login.textbetweennativeorsociallogin')}</div></div> : ''}
                        </Transition>
                        <Transition timer={100} mountAnimation="mount-heightAnimation">
                            <SocialConnections connections={nextAction === "password_required" ? [] : socialConnections} onclick={SocialSignIn} />
                        </Transition>

                        {socialConnections.length !== 0 ?
                            <Transition timer={100} mountAnimation="mount-heightAnimation">
                                {nextAction === "password_required" ? "" : <div className="meta">{ReactHtmlParser(termsText)} </div>}
                            </Transition>
                            : ""}
                    </>
                    : ""
                }
               

       
         
            </div>
          
        </Fragment>
       

     
       );

}

